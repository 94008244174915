import React from 'react'

const SvgArchitectural09 = props => (
  <svg viewBox='0 0 58.01 50' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M45.49 15.27a4.18 4.18 0 10-4.18 4.16 4.16 4.16 0 004.18-4.16zm-5.57 0a1.39 1.39 0 01.41-1 1.39 1.39 0 11-.41 1zm9.74 8.59H36l-9.4-11.37a7 7 0 00-9.84 0L7 24.24a1.38 1.38 0 000 1.84 1.39 1.39 0 001.85.13l9.8-11.77a4.26 4.26 0 015.9 0l9.82 11.77a1.4 1.4 0 001 .4h14.29a1.39 1.39 0 000-2.75zM52.45 0H5.57A5.56 5.56 0 000 5.55v33.32a5.55 5.55 0 005.57 5.55h16.26a1.39 1.39 0 100-2.77H5.57a2.8 2.8 0 01-2.79-2.78V5.55a2.78 2.78 0 012.79-2.77h46.88a2.78 2.78 0 012.78 2.77v33.32a2.79 2.79 0 01-2.78 2.78 1.39 1.39 0 100 2.77A5.55 5.55 0 0058 38.87V5.55A5.56 5.56 0 0052.45 0zM46 37.48h-1.9v-4.16a2.78 2.78 0 00-2.79-2.78H33a2.78 2.78 0 00-2.78 2.78v4.16h-1.97a2.14 2.14 0 00-2.24 2 2.33 2.33 0 00.85 1.87l8.22 7.79a3 3 0 004.17 0l8.23-7.79a2.68 2.68 0 00.79-1.87 1.9 1.9 0 00-2.24-2zm-8.72 9.72a.28.28 0 01-.28 0l-7.28-6.94h.46a2.79 2.79 0 002-.82 2.76 2.76 0 00.81-2v-4.12h8.35v4.16a2.8 2.8 0 002.79 2.78h.47z'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgArchitectural09
