import React from 'react'

const SvgArchitectural07 = props => (
  <svg viewBox='0 0 50.01 49.99' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M1.68 9.35h7.46a7.12 7.12 0 0013.57 0h25.62A1.72 1.72 0 0050 7.59a1.72 1.72 0 00-1.68-1.76H22.93a7.12 7.12 0 00-14 0H1.68A1.72 1.72 0 000 7.59a1.72 1.72 0 001.68 1.76zm14.25-5.83a3.63 3.63 0 11-3.62 3.63 3.63 3.63 0 013.62-3.63zm32.39 19.31H35a7.12 7.12 0 00-13.94.1H1.68a1.76 1.76 0 000 3.52h19.59a7.12 7.12 0 0013.66-.1h13.39a1.76 1.76 0 000-3.52zM28.09 28a3.64 3.64 0 113.61-3.64A3.63 3.63 0 0128.09 28zm20.23 13.08H30.5a7.11 7.11 0 00-13.8 0h-15a1.76 1.76 0 000 3.52h15a7.11 7.11 0 0013.8 0h17.82a1.76 1.76 0 000-3.52zM23.6 46.47a3.63 3.63 0 113.62-3.63 3.63 3.63 0 01-3.62 3.63z'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgArchitectural07
