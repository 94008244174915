import React from 'react'
import PropTypes from 'prop-types'
import { Button, Flex, Toast } from '@renderbus/common/components'
import styled from 'styled-components'
import { typography } from '@renderbus/common/theme'
import SendEmail from './send-email'

const InputStyle = `
  flex-grow:1;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 4px;
`
const Form = styled.form`
  max-width: 400px;
  margin: auto;
`
const InputRow = styled(Flex)`
  margin: 10px 0;
  font-size: ${typography.textSmall};
  align-items: flex-start;
  label {
    margin: 0 1em;
    line-height: 30px;
    font-weight: bold;
    color: #666;
  }
  input {
    ${InputStyle};
  }
  textarea {
    height: 120px;
    ${InputStyle};
  }
`
const ButtonGroup = styled(Flex)`
  margin: 20px 0 0 5.25em;
  justify-content: space-between;
  button {
    width: 9em;
  }
`
const sendEmail = new SendEmail()
class JoinUsForm extends React.PureComponent {
  state = {
    comment: '',
    name: '',
    phone: '',
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleSubmit = event => {
    event.preventDefault()
    let data = {
      ...this.state,
      text: this.props.agentType,
    }
    if (data.name === '' || data.phone === '' || data.comment === '') {
      Toast.info('为了方便客服联系您，请您一定要填写完表单并确保填写信息的真实性！')
      return false
    }
    if (!/^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/.test(this.state.phone)) {
      console.log('请输入正确的电话号码')
      Toast.info('请输入正确的电话号码')
      return false
    }
    let is_send = localStorage.getItem('send-' + data.name + data.phone + data.comment)
    if (is_send) {
      Toast.info('已经申请过了，请勿重复申请')
      return false
    }
    sendEmail.agentApply(data.name, data.phone, data.comment)
  }
  render() {
    const { onClose } = this.props
    return (
      <Form onSubmit={this.handleSubmit}>
        <InputRow>
          <label htmlFor='comment-input'>详细信息</label>
          <textarea
            id='comment-input'
            type='text'
            name='comment'
            value={this.state.comment}
            onChange={this.handleInputChange}
            placeholder='请输入您想要咨询的渲染优惠咨询或合作信息，并留下真实有效的联系方式，我们的同事会在最短的时间内回复您！'
          />
        </InputRow>
        <InputRow>
          <label htmlFor='name-input'>联系姓名</label>
          <input
            id='name-input'
            type='text'
            name='name'
            value={this.state.name}
            onChange={this.handleInputChange}
            placeholder='联系人姓名'
          />
        </InputRow>
        <InputRow>
          <label htmlFor='phone-input'>联系电话</label>
          <input
            id='phone-input'
            type='text'
            name='phone'
            value={this.state.phone}
            onChange={this.handleInputChange}
            placeholder='常用联系方式'
          />
        </InputRow>
        <ButtonGroup>
          {/* // to do
          // grey btn */}
          <Button
            onClick={e => {
              onClose && onClose(e)
            }}
          >
            关闭
          </Button>
          <Button type='submit'>提交留言</Button>
        </ButtonGroup>
      </Form>
    )
  }
}

JoinUsForm.propTypes = {
  text: PropTypes.string,
  onClose: PropTypes.func,
}

export default JoinUsForm
