import React from 'react'

const SvgArchitectural18 = props => (
  <svg viewBox='0 0 106.96 100' {...props}>
    <defs>
      <style>{'.architectural_18_svg__cls-1{fill:#13cb75}'}</style>
    </defs>
    <g id='architectural_18_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='architectural_18_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='architectural_18_svg__cls-1'
          d='M8.7 56.52c.11-.2.12-.2 0 0 .14-.18.16-.16 0 0 .16-.11.2-.12.35 0h.08-.08.07c.07 0 .07 0 0 0h.44c-.14 0-.13 0 0 0-.09 0-.06 0 0 0s0-.06 0 0 .06-.1 0 0l3.91-4.35a.5.5 0 000-.87.64.64 0 00-.87 0L10 54.35A43 43 0 0131.3 18.7a.78.78 0 00.44-.87.8.8 0 00-.87-.44 44.56 44.56 0 00-22.17 37L6.09 51.3a1.35 1.35 0 00-1.31 0 2 2 0 000 .87z'
        />
        <path
          className='architectural_18_svg__cls-1'
          d='M9.57 56.52c.17-.18.15-.17 0 0zM75.65 23.91c-.09.25.17.44.44.44.54-.13.74-.48.43-.87L75.65 20a42.22 42.22 0 0120 36.09.65.65 0 00.44.43c.57 0 .87-.29.87-.43A43.76 43.76 0 0076.09 18.7l3.91-.87a1.07 1.07 0 00.87-.87c-.27-.28-.62-.5-.87-.44l-5.65 1.31c-.08-.07-.25 0-.44.43v.44zM74.78 93l-5.21-1.7c-.46-.07-.83.12-.87.44a.63.63 0 00.43.87l3.87 1.3a46 46 0 01-20.39 4.79A43.65 43.65 0 0131.3 93c-.2-.19-.6-.09-.87.44-.09.09 0 .49.44.87A45.41 45.41 0 0052.61 100a43.39 43.39 0 0020.87-5.22l-1.31 3.92c-.31.17-.14.55 0 .87.27-.17.35-.16.44 0 .25-.16.5-.31.43-.44l2.18-5.22a1.57 1.57 0 000-.43c-.15-.34-.29-.48-.44-.48zM53.48 36.09a18 18 0 10-18.26-17.83 18 18 0 0018.26 17.83zm0-34.79a16.74 16.74 0 11-17 17 16.76 16.76 0 0117-17zM36.09 75.65a18 18 0 10-17.83 17.83 18.08 18.08 0 0017.83-17.83zM18.26 92.17a16.74 16.74 0 1116.52-16.52 16.76 16.76 0 01-16.52 16.52zM89.13 57.39A18 18 0 10107 75.65a18.16 18.16 0 00-17.87-18.26zm0 34.78a16.74 16.74 0 1116.52-16.52 16.76 16.76 0 01-16.52 16.52zM85.22 80.87z'
        />
        <path
          className='architectural_18_svg__cls-1'
          d='M85.65 79.57l-4.78-4.35 4.35-3.92a1.93 1.93 0 000-1.3 2 2 0 00-.87 0l-5.22 4.78a5 5 0 010 .87 2.61 2.61 0 00.44.44l5.62 4.76s.07 0 0 0a.93.93 0 00.87-.44c.1-.03.07-.41-.41-.84zM93.48 70a2 2 0 00-.87 0 1.93 1.93 0 000 1.3L97 75.22l-4.79 4.35c-.48.4-.51.81-.43.86a.92.92 0 00.87.44l5.63-4.76a2.61 2.61 0 00.44-.44 3.61 3.61 0 010-.87zM92.63 80.85s.01 0 0 0zM90.87 67.83c-.47-.06-.83.16-.87.43l-3 13.48a.77.77 0 00.43.87h.44a.6.6 0 00.43-.44l3-13.47a.76.76 0 00-.43-.87zM53 27.83a7.42 7.42 0 007.39-7.4C60.43 17 58.73 15 57 13a43.66 43.66 0 01-3.48-4.78c0-.29-.19-.43-.44-.43s-.47.14-.43.43A43.66 43.66 0 0149.13 13c-1.77 2-3.48 4-3.48 7.39A7.43 7.43 0 0053 27.83zm-3-13.92A21.84 21.84 0 0053 10a21.87 21.87 0 003.05 3.91c1.63 2 3 3.69 3 6.52a6.09 6.09 0 11-12.17 0C47 17.6 48.37 16 50 13.91z'
        />
        <path
          className='architectural_18_svg__cls-1'
          d='M53 24.35a.77.77 0 00.87-.87c0-.16-.29-.46-.87-.44a2.8 2.8 0 01-2.61-2.61c0-.57-.27-.86-.43-.86a.77.77 0 00-.87.86A4 4 0 0053 24.35zM13.87 68.25a.93.93 0 00-.89-.64h-.08a1 1 0 00-.91.64L6.83 79.54a.86.86 0 00-.09.38.65.65 0 00.66.66.74.74 0 00.69-.49l1.32-3h7l1.33 2.93a.79.79 0 00.71.53.69.69 0 00.69-.69 1.11 1.11 0 00-.09-.37zM10 75.81l2.9-6.51 2.93 6.51zM24.26 71a7.41 7.41 0 00-3.07.64.64.64 0 00-.38.58.63.63 0 00.62.6.65.65 0 00.25-.06 5.85 5.85 0 012.44-.52c1.76 0 2.8.87 2.8 2.56v.33a9.92 9.92 0 00-2.89-.4c-2.39 0-4 1-4 3 0 1.93 1.76 2.93 3.51 2.93a4.15 4.15 0 003.4-1.62v.85a.64.64 0 00.67.66.66.66 0 00.67-.69v-5.12a3.71 3.71 0 00-1-2.74 4.09 4.09 0 00-3.02-1zm2.67 6c0 1.49-1.41 2.55-3.14 2.55-1.27 0-2.36-.69-2.36-1.88 0-1.18 1-2 2.74-2a9.75 9.75 0 012.76.4z'
        />
      </g>
    </g>
  </svg>
)

export default SvgArchitectural18
