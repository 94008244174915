import React from 'react'

const SvgArchitectural16 = props => (
  <svg viewBox='0 0 120 120' {...props}>
    <defs>
      <style>{'.architectural_16_svg__cls-2{fill:#fff}'}</style>
    </defs>
    <g id='architectural_16_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='architectural_16_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path d='M60 0A60 60 0 110 60 60 60 0 0160 0z' fill='#13cb75' fillRule='evenodd' />
        <path
          className='architectural_16_svg__cls-2'
          d='M48.09 49.36a11.86 11.86 0 0011.82 11.88 11.71 11.71 0 008.35-3.47 11.85 11.85 0 10-20.17-8.41zm11.82-9.2a9.2 9.2 0 11-9.18 9.2 9.2 9.2 0 019.18-9.2zm15.85 22h-31.6a2.34 2.34 0 00-2.37 2.07l-5.28 23.36v.15A2.32 2.32 0 0038.88 90H81a2.32 2.32 0 002.37-2.26v-.15l-5.25-23.34a2.33 2.33 0 00-2.36-2.07zM39.27 87.32l5.09-22.46h15.47l-4.28 4.29a.8.8 0 00-.2.75l4 16.15a.78.78 0 001.51 0l4-16.15a.8.8 0 00-.2-.75l-4.28-4.29h15.17l5.09 22.46z'
        />
        <path
          className='architectural_16_svg__cls-2'
          d='M45.09 49.29a1.12 1.12 0 00.4.07 1.35 1.35 0 00.41-2.62 6.93 6.93 0 01-4.38-6.46 7.56 7.56 0 017.55-7.56 6.73 6.73 0 015.81 3.52 1.3 1.3 0 001.82.4 1.34 1.34 0 00.39-1.85c-2-3.11-4.76-4.75-8-4.75a10.22 10.22 0 00-10.21 10.24 9.58 9.58 0 006.21 9zm-5.88 21.37a1.34 1.34 0 00-1.32-1.34h-4.57l4-17h8.56a1.34 1.34 0 000-2.68h-8.95a2.15 2.15 0 00-2.17 1.86L30.5 69.79v.15A2.12 2.12 0 0032.67 72h5.22a1.34 1.34 0 001.32-1.34zm24-34a1.31 1.31 0 001.82-.4 6.76 6.76 0 015.82-3.53 7.56 7.56 0 017.55 7.57 6.93 6.93 0 01-4.4 6.42 1.34 1.34 0 00.4 2.61 1.14 1.14 0 00.41-.07 9.57 9.57 0 006.21-9A10.23 10.23 0 0070.86 30a9.36 9.36 0 00-8 4.75 1.35 1.35 0 00.39 1.86zm22 14.88a2.14 2.14 0 00-2.17-1.86h-8.89a1.34 1.34 0 000 2.68h8.56l4 17h-4.6a1.34 1.34 0 000 2.68h5.22a2.12 2.12 0 002.17-2.06v-.16z'
        />
      </g>
    </g>
  </svg>
)

export default SvgArchitectural16
