import React from 'react'

const SvgArchitectural10 = props => (
  <svg viewBox='0 0 47.01 49.99' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M23.45 0C10.55 0 0 11 0 24.5a24.31 24.31 0 0015.88 23.06.38.38 0 00.32.11 1.31 1.31 0 001.07-.78 1.13 1.13 0 00-.64-1.44A22.17 22.17 0 012.24 24.39c0-12.31 9.59-22.28 21.32-22.28S45 12.08 45 24.39a22.89 22.89 0 01-4.9 14.3 1.1 1.1 0 00.1 1.55 1 1 0 001.5-.11A24.93 24.93 0 0047 24.5C47 11 36.45 0 23.45 0zm-.11 11.2A12.9 12.9 0 0136 24.39a11.71 11.71 0 01-.32 3 1.11 1.11 0 00.64 1.44 1.05 1.05 0 001.38-.66 12 12 0 00.54-3.66c0-8.54-6.61-15.41-14.82-15.41S8.63 16 8.63 24.5a15.24 15.24 0 003 9.2 1 1 0 00.85.44.58.58 0 00.53-.33 1.22 1.22 0 00.22-1.55 13.53 13.53 0 01-2.56-7.87A12.9 12.9 0 0123.34 11.2zm12.79 19.06a2.16 2.16 0 00-1.28.34A3 3 0 0032 28.71a2.74 2.74 0 00-1.3.29 3 3 0 00-2.88-1.88 1.72 1.72 0 00-1.07.22v-2.51a3.15 3.15 0 10-6.29 0v10.75l-1.27-1.33a3.2 3.2 0 00-4.48 0 4.4 4.4 0 00-1.07 2.22 3.19 3.19 0 00.86 2.66l8 8.32a1.87 1.87 0 00.53.44C24.62 49.22 26.33 50 30 50c8.42 0 9.17-5 9.17-10.53v-5.88a3.54 3.54 0 00-.75-2.33 2.86 2.86 0 00-2.29-1zm-6.07 17.41c-3 0-4.69-.67-6.18-2.11l-7.57-7.76a1.39 1.39 0 010-1.77A1.26 1.26 0 0118 36l2.77 2.88a1.11 1.11 0 001.28.22 1.17 1.17 0 00.75-1V25.27a1.19 1.19 0 011.07-1.21 1 1 0 011 1.1v9.65a.9.9 0 00.95 1.11 1 1 0 001.07-1.11v-4a1 1 0 011-1.11 1 1 0 011 1.11V36a1.07 1.07 0 102.13 0v-3.63a1 1 0 011-1.11 1.1 1.1 0 011.07 1.11V37a1.07 1.07 0 102.13 0v-3.19a1 1 0 011-1.11 1 1 0 01.64.22 1.15 1.15 0 01.32.89v6h.1c-.19 4.53-.4 7.86-7.22 7.86zM17.37 26.38zm5.12-8a5.44 5.44 0 016.4 4.44 1.07 1.07 0 001.27.88 1.13 1.13 0 00.84-1.31 7.68 7.68 0 00-3.2-5 7.19 7.19 0 00-5.65-1.1 7.37 7.37 0 00-4.8 3.32 8 8 0 00-1.06 5.88 1 1 0 001 .87 1.13 1.13 0 00.82-1.32 5.88 5.88 0 01.89-4.2 6.11 6.11 0 013.49-2.44z'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgArchitectural10
