import styled from 'styled-components'
import { typography, spacing, color, Media, hideSMDown, mbSize } from '@renderbus/common/theme'
import { H1, H2, H3, Flex, Button } from '@renderbus/common/components'
import { Link } from 'gatsby'
export const AdBanner = styled.img`
  cursor: pointer;
`
export const BannerContainer = styled.div`
  position: relative;
  padding-top: 240px;
  padding-bottom: 40px;
  min-height: 100vh;
  text-align: center;
  color: white;
  img {
    width: 100px;
  }
  .bg_pc {
    display: block;
  }
  .bg_mb {
    display: none;
  }
  .PhoneComboBox {
    width: 420px;
    margin: auto;
  }
  ${Media.lessThan(Media.small)} {
    padding-top: ${mbSize(180)};
    min-height: ${mbSize(900)};
    padding-bottom: 0;
    img {
      width: ${mbSize(120)};
      height: ${mbSize(120)};
    }
    .PhoneComboBox {
      justify-content: center;
      width: ${mbSize(570)};
      input {
        height: ${mbSize(60)};
      }
      button {
        height: ${mbSize(60)};
        line-height: ${mbSize(60)};
      }
    }
    .bg_pc {
      display: none;
    }
    .bg_mb {
      display: block;
    }
  }
`
export const BannerTitle = styled(H1)`
  font-size: 48px;
  font-weight: 500;
  margin-top: 40px;
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(49)};
    span {
      display: block;
      font-size: ${mbSize(60)};
      font-weight: 500;
      line-height: ${mbSize(70)};
    }
  }
`
export const BannerSubTitle = styled.p`
  font-size: ${typography.h4};
  margin: ${spacing.small} 0 50px;
  font-weight: 300;
`
export const BannerMessageText = styled.p`
  margin: 30px 0;
  font-size: ${typography.h4};
  span {
    font-size: ${typography.text};
    width: 160px;
  }
  ${Media.lessThan(Media.small)} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${mbSize(70)};
    margin-bottom: ${mbSize(90)};
    font-size: ${mbSize(28)};
  }
`
export const BannerMessageCoupon = styled.span`
  display: inline-block;
  height: 30px;
  width: 200px;
  line-height: 30px;
  background-color: rgb(236, 105, 65);
  border-radius: 30px 30px 30px 0;
  margin-left: 5px;
  font-size: ${typography.h4};
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(240)};
    font-size: ${mbSize(24)};
    margin-left: ${mbSize(20)};
  }
`
export const BannerSupportText = styled.p`
  margin: 30px 0 0;
  font-size: ${typography.textSmall};
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(40)};
  }
`

export const AdvantageContainer = styled.div`
  padding: 100px 0;
  margin: auto;
  background: ${p => p.lighter && color.panel};
  text-align: center;
  .header {
    margin: 20px auto 80px;
  }
  .become-member {
    margin-top: 85px;
    background: #13cb75;
  }
  ${p => p.hideSMDown && hideSMDown}
  ${Media.lessThan(Media.small)} {
    padding: 40px 0;
    .three-gift {
      justify-content: space-around;
    }
    .become-member {
      margin-top: 40px;
    }
    .simple-usage {
      row-gap: 40px;
    }
  }
`
export const AdvantageTitle = styled(H2)`
  font-size: 2.5rem;
  margin-bottom: 80px;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h2};
    margin-bottom: 50px;
  }
`
export const AdvantageSubTitle = styled.p`
  margin-bottom: 80px;
  color: white;
`
export const IconBox = styled(Flex)`
  flex-direction: column;
  font-size: ${p => (p.normalFontSize ? typography.text : typography.h4)};
  color: white;
  text-align: center;
  svg {
    height: 60px;
  }
  svg,
  img {
    margin-bottom: ${spacing.base};
  }
  p {
    margin: 0;
  }
`

export const IconGiftBox = styled(Flex)`
  flex-direction: column;
  font-size: ${typography.text};
  color: white;
  text-align: center;
  svg {
    height: 70px;
    margin-bottom: ${spacing.base};
  }
  p {
    margin: 0;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 0.88rem;
    svg {
      height: 50px;
      margin-bottom: 16px;
    }
  }
`

export const LighterBg = styled.div`
  background: ${color.panel};
`
export const InfoContainer = styled(Flex)`
  padding: 130px 0;
  align-items: center;
  ${Media.lessThan(Media.small)} {
    padding: 40px 0;
  }
`
export const InfoImg = styled.img`
  width: 50%;
  text-align: center;
  ${hideSMDown};
`
export const InfoContent = styled.div`
  width: 50%;
  padding: ${p => (p.position === 'left' ? '0 50px 0 0' : '0 0 0 50px')};
  ${Media.lessThan(Media.small)} {
    width: 100%;
    padding: 0;
    > div:first-of-type {
      display: flex;
      justify-content: space-around;
    }
  }
`
export const InfoTitle = styled(H2)`
  margin-bottom: ${spacing.base};
  font-weight: 500;
  font-size: 36px;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h3};
  }
`
export const InfoSubTitle = styled.p`
  color: ${color.prompt};
`
export const IconInlineBox = styled.div`
  padding: 30px 40px 30px 0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  color: ${color.prompt};
  text-align: center;
  &:last-of-type {
    padding-right: 0;
  }
  svg {
    height: 50px;
  }
`

export const CompanyIcons = styled(Flex)`
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  ${Media.lessThan(Media.small)} {
    row-gap: 40px;
  }
`
export const CompanyIconBox = styled(Flex)`
  flex-shrink: 0;
  width: 190px;
  flex-direction: column;
  color: white;
  text-align: center;
  text-decoration: none;
  ${Media.lessThan(Media.small)} {
    flex-basis: 50%;
    max-width: 50%;
  }
`
export const IconBall = styled(Flex)`
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background: ${color.panel};
  img {
    max-width: 50px;
  }
`

export const AgentBox = styled.div`
  position: relative;
  max-width: 420px;
  margin: 50px auto 0;
  padding: 100px 50px 50px;
  text-align: center;
  background: #2c2c2c;
  ${Media.lessThan(Media.small)} {
    background: none;
    padding: 60px 0 0;
  }
`
export const AgentTitle = styled(H3)`
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h4};
  }
`
export const AgentContent = styled.p`
  height: 150px;
  font-size: ${typography.textSmall};
  color: ${color.prompt};
  ${hideSMDown};
`
export const AgentIcon = styled(Flex)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    height: 120px;
    width: 120px;
  }
  ${Media.lessThan(Media.small)} {
    height: 100px;
    width: 100px;
  }
`
export const AgentButton = styled(Button)`
  ${hideSMDown};
`

export const BiggerIconBox = styled(Flex)`
  flex-direction: column;
  margin: auto;
  width: 250px;
  svg {
    height: 100px;
  }
`
export const BiggerIconTitle = styled(H3)`
  padding: 50px 0 20px;
  font-weight: 500;
`
export const BiggerIconContent = styled.p`
  font-size: ${typography.textSmall};
  color: ${color.prompt};
`
export const RegisterFreeConatiner = styled(Flex)`
  justify-content: flex-start;
  color: ${color.prompt};
  margin: 40px 0;
  > div {
    margin-left: 20px;
    width: 260px;
  }
  p {
    margin: 0;
  }
`

export const AdvertiseContainer = styled(Link)`
  width: 100%;
  margin-top: ${p => p.marginTop || 'unset'};
  display: ${p => (p.marginTop ? 'block' : 'unset')};
  img {
    width: 100%;
  }
`

export const AdvertiseBanner = styled.img`
  cursor: pointer;
  width: 100%;
  &.pc {
    display: block;
    width: 1000px;
    height: 160px;
    margin: auto;
    margin-top: 48px;
    position: relative;
    border-radius: 40px 40px 20px 20px;
  }
  &.pc:hover {
    box-shadow: 0px 20px 18px -8px rgba(0, 0, 0, 0.3);
  }
  &.mb {
    display: none;
    width: 100%;
    min-height: ${mbSize(220)};
  }
  ${Media.lessThan(Media.small)} {
    &.pc {
      display: none;
    }
    &.mb {
      display: block;
    }
  }
`
