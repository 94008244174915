import React from 'react'

const SvgArchitectural14 = props => (
  <svg viewBox='0 0 120 120' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <g data-name='\u56FE\u5C42 1'>
        <path d='M60 0A60 60 0 110 60 60 60 0 0160 0z' fill='#13cb75' fillRule='evenodd' />
        <path
          d='M87.16 73.29l.13-.14L74.76 60l9.87-10.37a5.36 5.36 0 00.4-7.08l-8.22-8.78a4.72 4.72 0 00-6.74.42l-10 10.38-12.54-13.19a4.8 4.8 0 00-6.74 0l-7.95 8.17a5.29 5.29 0 000 7.08l8.7 9.42a1.62 1.62 0 002.37 0 1.84 1.84 0 000-2.49L39.72 49l3.77-4a1.86 1.86 0 00.51-1.72A1.75 1.75 0 0042.74 42a1.63 1.63 0 00-1.64.48l-3.78 4-2.09-2.18a1.62 1.62 0 01-.44-1.12 1.6 1.6 0 01.44-1.11l7.82-8.22a1.51 1.51 0 012.12 0l12.5 13.21L38 67.77a1.84 1.84 0 00-.47.88l-3.37 17.1a1.87 1.87 0 00.35 1.48 1.66 1.66 0 001.31.65h.4l15.63-4.18a1.76 1.76 0 00.81-.67l19.71-20.71L84.9 75.49a1.61 1.61 0 01.45 1.11 1.65 1.65 0 01-.45 1.12l-7.82 8.21a1.43 1.43 0 01-2.12 0l-2.09-2.2 3.78-4a1.86 1.86 0 00-.05-2.47 1.65 1.65 0 00-2.35 0l-3.74 4.11-4.34-4.57a1.65 1.65 0 00-2.35 0 1.86 1.86 0 000 2.47l8.83 9.27a4.71 4.71 0 006.74 0l7.82-8.21a5.29 5.29 0 000-7.08zm-14.83-36.6a1.56 1.56 0 012.12-.43l8.19 8.6a1.77 1.77 0 01-.4 2.24l-3.1 3.25-9.94-10.41zM50.6 80.34L38 83.88l2.72-13.81 26.09-27.44L76.71 53z'
          fill='#fff'
        />
        <path d='M58.48 60A1.52 1.52 0 1060 58.4a1.56 1.56 0 00-1.52 1.6z' fill='none' />
      </g>
    </g>
  </svg>
)

export default SvgArchitectural14
