import React from 'react'

const SvgArchitectural03 = props => (
  <svg viewBox='0 0 78.01 60.02' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M10.65 11.62a1 1 0 00-1 1V15H7.26a1 1 0 000 2h2.37v2.39a1 1 0 102 0v-2.31H14a1 1 0 000-2h-2.33v-2.43a1 1 0 00-1.02-1.03zm-.88 24.83a1.21 1.21 0 00.86-.49l10.13-10.33a3.42 3.42 0 014.77 0L35.05 35a5 5 0 003.54 1.47h6.81a1.23 1.23 0 000-2.46h-6.81a2.56 2.56 0 01-1.83-.73l-9.52-9.35a5.84 5.84 0 00-8.19.13L8.92 34.36a1.22 1.22 0 00-.26 1.33 1.19 1.19 0 001.11.76zm36-11.68a2.44 2.44 0 10-2.44 2.46 2.45 2.45 0 002.47-2.46zM25.62 46.85H5a2.33 2.33 0 01-2.57-2.21V10.39A2.31 2.31 0 015 8.18h48.62a2.32 2.32 0 011.75.54 2.35 2.35 0 01.81 1.67v16.12a1.22 1.22 0 102.44 0V10.39A4.8 4.8 0 0057.1 7a4.72 4.72 0 00-3.48-1.27H5A4.75 4.75 0 001.52 7 4.8 4.8 0 000 10.39v34.25a4.77 4.77 0 005 4.67h20.65a1.23 1.23 0 100-2.46zM14.05 2.36h46.18a3.88 3.88 0 013.84 3.87v19a1.18 1.18 0 102.35 0v-19A6.22 6.22 0 0060.23 0H14.05a1.17 1.17 0 00-1.17 1.18 1.17 1.17 0 001.17 1.18zm56.2 41.14a12.23 12.23 0 00-12.41-12 12.46 12.46 0 00-10.74 6H47a9.17 9.17 0 00-9.31 9v.07a6.71 6.71 0 000 13.39V60H62h9.78S78 59.12 78 51.74a8.38 8.38 0 00-7.75-8.24zm-6.46 11.29H52.11v2.59L47 53.22l5.11-3.61v2.59h11.68a1.35 1.35 0 011.3 1.28 1.38 1.38 0 01-1.3 1.31zM59.92 47v-2.6H47l-.13-.92.13-1.67h12.92v-2.6l6 4.28z'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgArchitectural03
