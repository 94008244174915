import React from 'react'

const SvgArchitectural11 = props => (
  <svg
    id='architectural_11_svg___\u56FE\u5C42_1'
    data-name='\u56FE\u5C42 1'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <style>{'.architectural_11_svg__cls-1{fill:#13cb75;stroke-width:0}'}</style>
    </defs>
    <path
      className='architectural_11_svg__cls-1'
      d='M18 17.2H6c-.4 0-.8-.3-.8-.8v-5.1C5.2 7.7 7 4.4 10 2.4l.7-.5c.8-.5 1.7-.5 2.5 0l.7.5c3 2 4.8 5.3 4.8 8.9v5.1c0 .4-.3.8-.8.8zM6.8 15.8h10.5v-4.4c0-3.1-1.5-6-4.1-7.7l-.7-.5c-.3-.2-.6-.2-.8 0l-.7.5c-2.6 1.7-4.1 4.6-4.1 7.7v4.4z'
    />
    <path
      className='architectural_11_svg__cls-1'
      d='M21 17.2H3c-.4 0-.8-.3-.8-.8V14c0-.2 0-.4.2-.5l3-3.1c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-2.8 2.9v1.3h16.5v-1.3l-2.8-2.9c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l3 3.1c.1.1.2.3.2.5v2.4c0 .4-.3.8-.8.8zM12 22.8c-.4 0-.8-.3-.8-.8v-3c0-.4.3-.8.8-.8s.8.3.8.8v3c0 .4-.3.8-.8.8zM8 21.8c-.4 0-.8-.3-.8-.8v-1c0-.4.3-.8.8-.8s.8.3.8.8v1c0 .4-.3.8-.8.8zM16 21.8c-.4 0-.8-.3-.8-.8v-1c0-.4.3-.8.8-.8s.8.3.8.8v1c0 .4-.3.8-.8.8z'
    />
    <path
      className='architectural_11_svg__cls-1'
      d='M13.4 13.4h-1.2l-.5-1.2H9.5l-.4 1.2H7.9L10 8h1.2l2.2 5.4zm-2-2.2l-.8-2-.7 2h1.5zM13.9 13.4V8H15v5.4h-1.1z'
    />
  </svg>
)

export default SvgArchitectural11
