import React from 'react'

const SvgArchitectural02 = props => (
  <svg viewBox='0 0 70 60' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M11.74 16.29a1.13 1.13 0 001.12-1.13v-2.63h2.61a1.13 1.13 0 000-2.26h-2.61V7.64a1.12 1.12 0 10-2.24 0v2.63H8a1.13 1.13 0 000 2.26h2.62v2.63a1.13 1.13 0 001.12 1.13zM45.09 21a2.69 2.69 0 102.69-2.71A2.69 2.69 0 0045.09 21zm24.47 6.7a1.17 1.17 0 00-1-.2l-14.3 3.73a1.22 1.22 0 00-.43-.08h-11.3a2.83 2.83 0 01-2-.81L30 20.05a6.44 6.44 0 00-9 .13L9.83 31.56a1.35 1.35 0 00.94 2.3 1.36 1.36 0 00.94-.54l11.17-11.38a3.77 3.77 0 015.25 0l10.5 10.3a5.49 5.49 0 003.9 1.62h8.2a1.14 1.14 0 00.19.19l5.86 4.25-7.79 9-8.09-8.39a1.14 1.14 0 00-1.71.07L23.53 58.12a1.15 1.15 0 00.06 1.54 1.13 1.13 0 00.82.34 1.16 1.16 0 00.7-.24L40 48l8.24 8.73a1.14 1.14 0 001.69 0l12.41-14.38 5.88 3.95a1.14 1.14 0 001.78-1V28.61a1.16 1.16 0 00-.44-.91zm-1.85 15.5l-4.93-3.32a1.15 1.15 0 00-1.5.2L49 54.25l-8.1-8.57a1.11 1.11 0 00-.82-.36 1.12 1.12 0 00-.71.25L32 51.4l8.15-10 8.07 8.4a1.12 1.12 0 00.85.35 1.15 1.15 0 00.83-.39l9.44-10.94a1.16 1.16 0 00-.2-1.69L54.4 33.7a1.36 1.36 0 00.39-.27l12.92-3.33zm-41.93 6.63a1.37 1.37 0 00-1.36-1.07H5.52a2.57 2.57 0 01-2.83-2.44V5.15a2.61 2.61 0 01.89-1.83 2.55 2.55 0 011.94-.6h53.57a2.51 2.51 0 011.93.6 2.54 2.54 0 01.89 1.83v17.76a1.35 1.35 0 002.7 0V5.15a5.32 5.32 0 00-1.68-3.74A5.21 5.21 0 0059.09 0H5.52a5.2 5.2 0 00-3.84 1.4A5.27 5.27 0 000 5.15v41.17a5.27 5.27 0 001.68 3.74 5.2 5.2 0 003.84 1.4h18.94a1.36 1.36 0 001.32-1.63z'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgArchitectural02
