import React from 'react'

const SvgArchitectural05 = props => (
  <svg viewBox='0 0 48 48' {...props}>
    <defs>
      <style>{'.architectural_05_svg__cls-1{fill:#13cb75}'}</style>
    </defs>
    <g id='architectural_05_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='architectural_05_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='architectural_05_svg__cls-1'
          d='M44.57 0H3.43A3.24 3.24 0 000 3.43v41.14A3.24 3.24 0 003.43 48h41.14A3.24 3.24 0 0048 44.57V3.43A3.24 3.24 0 0044.57 0zM5.14 3.43h37.72a1.61 1.61 0 011.71 1.71v25.72H3.43V5.14a1.61 1.61 0 011.71-1.71zm37.72 41.14H5.14a1.61 1.61 0 01-1.71-1.71v-8.57h41.14v8.57a1.61 1.61 0 01-1.71 1.71z'
        />
        <path
          className='architectural_05_svg__cls-1'
          d='M6.86 37.71h3.43v3.43H6.86zm10.28 0h3.43v3.43h-3.43zm10.29 0h3.43v3.43h-3.43zm10.28 0h3.43v3.43h-3.43zm-17.48-11a2.75 2.75 0 002.74.35L40.8 9.94a1.65 1.65 0 000-2.4 1.67 1.67 0 00-2.4 0L21.94 23.31l-5.48-5.82a2.85 2.85 0 00-2.4 0 1.65 1.65 0 000 2.4z'
        />
      </g>
    </g>
  </svg>
)

export default SvgArchitectural05
