import React from 'react'

const SvgArchitectural13 = props => (
  <svg
    id='architectural_13_svg__\u56FE\u5C42_1'
    x={0}
    y={0}
    viewBox='0 0 24 24'
    xmlSpace='preserve'
    {...props}
  >
    <style>{'.architectural_13_svg__st0{fill:#13cb75}'}</style>
    <path
      className='architectural_13_svg__st0'
      d='M11.38 21.25c-5.1 0-9.25-4.15-9.25-9.25s4.15-9.25 9.25-9.25S20.62 6.9 20.62 12c0 .03.02.06.02.1h-1.27l2 3 2-3H22.1c0-.03.02-.06.02-.1 0-5.93-4.82-10.75-10.75-10.75S.62 6.07.62 12s4.82 10.75 10.75 10.75c.41 0 .75-.34.75-.75s-.33-.75-.74-.75z'
    />
    <path
      className='architectural_13_svg__st0'
      d='M12.59 17.81c.3-.28.51-.49.63-.64.18-.22.3-.44.38-.64.08-.2.12-.42.12-.64 0-.4-.14-.73-.42-.99-.28-.27-.67-.4-1.17-.4-.45 0-.83.12-1.13.35-.3.23-.48.61-.54 1.14l.96.1c.02-.28.09-.48.21-.6s.28-.18.48-.18c.2 0 .36.06.48.17.12.12.17.28.17.49 0 .19-.07.39-.2.59-.1.14-.36.42-.8.82-.54.5-.9.9-1.08 1.2s-.29.62-.33.96h3.37v-.89h-1.91c.05-.09.12-.17.2-.27.09-.11.28-.29.58-.57zM17.81 17.67h-.62v-3.18h-.81l-2.17 3.18v.84h2.05v1.01h.93v-1.01h.62v-.84zm-1.55 0h-1.15l1.15-1.71v1.71zM17.55 22.74h.7l1.26-5.18h-.72zM20.08 18.6h2.18a7.12 7.12 0 00-1.08 1.93c-.27.72-.41 1.43-.43 2.12h.93c0-.44.07-.94.22-1.51.15-.56.36-1.09.64-1.59s.56-.88.83-1.15v-.7h-3.28v.9zM12.16 7.55A2.895 2.895 0 009.3 5.1c-1.45 0-2.64 1.07-2.85 2.45a2.1 2.1 0 00.45 4.15c.28 0 .5-.22.5-.5V8a1.9 1.9 0 113.8 0v3.2a1.9 1.9 0 01-1.9 1.9c-.28 0-.5.22-.5.5s.22.5.5.5c1.45 0 2.64-1.07 2.86-2.45a2.11 2.11 0 001.65-2.05c-.01-1-.72-1.84-1.65-2.05zM5.8 9.6c0-.42.25-.78.6-.96v1.93c-.35-.19-.6-.55-.6-.97zm6.4.96V8.63c.35.18.6.54.6.96s-.25.79-.6.97z'
    />
  </svg>
)

export default SvgArchitectural13
