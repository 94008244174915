import { API, Axios } from '../../../../../common/utils'
import { Toast } from '@renderbus/common/components'
const CryptoJS = require('crypto-js')
const accessKey = 'siduherbaep9834hrajbrbfaf'

export default class SendEmail {
  initHeader() {
    this.header = {
      platform: 2,
      channel: 7,
      accessId: 'djlfajdslfjkadfoiernrtg',
      version: '1.0.0',
    }
  }
  initSignature(data, domainName, url) {
    let signKeys = [...Object.keys(this.header), ...Object.keys(data)]
    let signValues = { ...this.header, ...data }
    signKeys.sort()
    let strData = ''
    signKeys.forEach(key => {
      strData += `&${key}=${signValues[key]}`
    })
    let strAddress = `[POST]${domainName}:${url}${strData}`
    //let strAddress = "[POST]task.renderbus.com:/api/render/common/queryPlatforms&UTCTimestamp=1535957371&accessId=AKIDz8krbsJ5yKBZQpn74WFkmLPx3EXAMPLE&channel=7&nonce=11886&platform=1&version=1.0.0&zone=2"
    var signatureObj = CryptoJS.HmacSHA256(strAddress, accessKey)
    var signatureStr = signatureObj.toString(CryptoJS.enc.Base64)
    return signatureStr
  }
  agentApply(name = '', phone = '', comment = '') {
    let data = {
      contactName: name,
      phone: phone,
      detailMessage: comment,
    }
    let domainName = 'task.renderbus.com'
    let url = '/api/rendering/user/agent/agentApply'
    this.initHeader()
    let header = this.header
    header.UTCTimestamp = Math.floor(new Date().getTime() / 1000).toString()
    header.nonce = Math.floor(Math.random() * 1000000).toString()
    let signature = this.initSignature(data, domainName, url)
    header.signature = signature
    const instance = new Axios(API.auth.testHost)
    instance.instance.defaults.headers = header
    instance.post(API.auth.agentApply, data).then(resData => {
      if (resData.code === 200) {
        localStorage.setItem('send-' + name + phone + comment, true)
        Toast.info('成功申请！')
      } else {
        Toast.info(resData.message)
      }
    })
  }
}
