import React from 'react'

const SvgArchitectural01 = props => (
  <svg viewBox='0 0 76.75 59.99' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M12.59 31.64l2-1.15a1.22 1.22 0 00.56-.89 1.51 1.51 0 00-1.15-2 4 4 0 00-1.06 0l-2 1.15c-.08.3-.46.52-.56.89a1.51 1.51 0 001.15 2c.3.08.68-.14 1.05 0zm10 1.39a1.51 1.51 0 00-1.15-2 4 4 0 00-1.05 0L.93 42.55c-.3-.08-.76.44-.86.81a1.55 1.55 0 001.15 2 3.13 3.13 0 001-.06l19.34-11.18a1.71 1.71 0 001-1.09zM76.68 2a1.54 1.54 0 00-1.15-2c-.3-.08-.3-.08-.68.14l-71.27 10c-.68.14-1.14.34-1.32 1a2.14 2.14 0 00.85 1.92l22.55 17 3.55 28.06c.14.68.26 1.44 1.23 1.7.6.16 1.36 0 1.52-.56l44.26-56.7a1.08 1.08 0 01.46-.56zm-69 10.63l59.43-8.42L27 27.48zm23.91 41.51l-3.3-23.91L68.63 6.94zm-10.68-14.9a4 4 0 00-1.06 0L14 42.75a1.22 1.22 0 00-.56.89 1.55 1.55 0 001.15 2 3.87 3.87 0 001.05 0l6-3.45a1.22 1.22 0 00.56-.9 1.65 1.65 0 00-1.22-2z'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgArchitectural01
