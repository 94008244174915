import React from 'react'

const SvgArchitectural04 = props => (
  <svg viewBox='0 0 60 60' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M56.25 45H3.75A3.75 3.75 0 010 41.25V9.38a3.76 3.76 0 013.75-3.76h24.4V1.86a1.85 1.85 0 113.7 0v3.76h24.4A3.75 3.75 0 0160 9.38v31.87A3.75 3.75 0 0156.25 45zm0-31.88a3.75 3.75 0 00-3.75-3.74H31.87V30.85L41.68 21a1.82 1.82 0 012.6 0 1.85 1.85 0 010 2.61L31.4 36.52a.83.83 0 01-.08.12c-.06.06-.14.06-.2.1a1.85 1.85 0 01-.63.34l-.26.05a1.77 1.77 0 01-1.55-.49s-.05-.09-.08-.12L15.72 23.59a1.85 1.85 0 010-2.61 1.82 1.82 0 012.6 0l9.83 9.87V9.38H7.5a3.74 3.74 0 00-3.75 3.74V37.5a3.75 3.75 0 003.75 3.75h45a3.75 3.75 0 003.75-3.75zM5.62 48.75h48.75c.51 0 1.88.5 1.88 1v1.88c0 .5-1.37.86-1.88.86H5.62c-.51 0-1.87-.36-1.87-.86v-1.87c0-.51 1.36-1 1.87-1zm3.76 7.5h41.24c.51 0 1.88.44 1.88 1v1.88c0 .51-1.37.92-1.88.92H9.38c-.51 0-1.88-.41-1.88-.92V57.2c0-.51 1.36-1 1.88-1z'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgArchitectural04
