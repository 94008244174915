import React from 'react'

const SvgArchitectural12 = props => (
  <svg
    id='architectural_12_svg___\u56FE\u5C42_1'
    data-name='\u56FE\u5C42 1'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <style>{'.architectural_12_svg__cls-1{fill:#13cb75;stroke-width:0}'}</style>
    </defs>
    <path
      className='architectural_12_svg__cls-1'
      d='M12 22.8C6.1 22.8 1.2 18 1.2 12S6.1 1.2 12 1.2 22.8 6 22.8 12 18 22.8 12 22.8zm0-20c-5.1 0-9.2 4.1-9.2 9.2s4.1 9.2 9.2 9.2 9.2-4.1 9.2-9.2-4.1-9.2-9.2-9.2z'
    />
    <path
      className='architectural_12_svg__cls-1'
      d='M9.5 17.8h-.6c-.6-.1-1.2-.4-1.7-.9s-.8-1-.9-1.7 0-1.3.2-1.9c.2-.6.7-1.1 1.2-1.5.5-.4 1.2-.5 1.8-.5s.8.3.8.8-.3.8-.8.8-.7.1-1 .3c-.3.2-.5.5-.6.8s-.2.7 0 1c0 .3.2.7.5.9s.6.4.9.5h1c.3 0 .6-.4.8-.6.2-.3.3-.6.3-1v-5c0-.6.2-1.3.5-1.8.4-.5.9-1 1.5-1.2s1.2-.3 1.9-.2c.6.1 1.2.4 1.7.9s.8 1 .9 1.7 0 1.3-.2 1.9c-.2.6-.7 1.1-1.2 1.5s-1.2.5-1.8.5-.8-.3-.8-.8.3-.8.8-.8.7-.1 1-.3c.3-.2.5-.5.6-.8.1-.3.2-.7 0-1 0-.3-.2-.7-.5-.9s-.6-.4-.9-.5c-.3 0-.7 0-1 .1-.3.1-.6.4-.8.6-.2.3-.3.6-.3 1v5c0 .6-.2 1.3-.5 1.8s-.9 1-1.5 1.2c-.4.2-.8.2-1.2.2z'
    />
  </svg>
)

export default SvgArchitectural12
