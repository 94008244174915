import React from 'react'

const SvgArchitectural15 = props => (
  <svg viewBox='0 0 120 120' {...props}>
    <defs>
      <style>{'.architectural_15_svg__cls-2{fill:#fff}'}</style>
    </defs>
    <g id='architectural_15_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='architectural_15_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path d='M60 0A60 60 0 110 60 60 60 0 0160 0z' fill='#13cb75' fillRule='evenodd' />
        <path
          className='architectural_15_svg__cls-2'
          d='M60 90a10.71 10.71 0 01-3.47-.52L39.69 84a2.64 2.64 0 01-1.8-2.45V59.08a2.57 2.57 0 015.14 0v20.61l15.17 4.9a6.51 6.51 0 003.86 0l15.17-4.9V59.08a2.57 2.57 0 015.14 0V81.5a2.64 2.64 0 01-1.8 2.5l-16.84 5.48A13.51 13.51 0 0160 90z'
        />
        <path
          className='architectural_15_svg__cls-2'
          d='M60 71.19a5.43 5.43 0 01-3.09-.9L25.8 53.8l-.13-.13A3.61 3.61 0 0124 50.58a3.78 3.78 0 011.67-3.09l.13-.13 31.11-16.49a5.92 5.92 0 016.18 0L94.2 47.49l.13.13A3.61 3.61 0 0196 50.71a3.78 3.78 0 01-1.67 3.09l-.13.13-31.11 16.36a5.43 5.43 0 01-3.09.9zM30.81 50.58l28.55 15.2.13.13a.81.81 0 00.77 0l.13-.13 28.54-15.2-28.42-15.2-.12-.13a.83.83 0 00-.78 0l-.12.13zM92.91 83.43a2.58 2.58 0 01-2.57-2.58V62.43a2.58 2.58 0 115.15 0v18.42a2.59 2.59 0 01-2.58 2.58z'
        />
        <path
          className='architectural_15_svg__cls-2'
          d='M69.77 53.16H50.23a2.58 2.58 0 010-5.16H69.9a2.58 2.58 0 012.57 2.58 2.69 2.69 0 01-2.7 2.58z'
        />
      </g>
    </g>
  </svg>
)

export default SvgArchitectural15
