import React from 'react'

const SvgArchitectural06 = props => (
  <svg viewBox='0 0 50 50.01' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M24.44 45.84A20.63 20.63 0 014.07 25 20.63 20.63 0 0124.44 4.17a20.13 20.13 0 0116.76 9l-5.2-1.9-1.35 3.91 11.87 4.3L50 8.37 46.14 7.1l-1.3 4.13a24.78 24.78 0 00-7.4-7.41 24.08 24.08 0 00-32.11 5.6 25.42 25.42 0 001.83 33.26A24 24 0 0033.8 48.1 25 25 0 0048.89 25h-4.08a20.63 20.63 0 01-20.37 20.84zm-2.83-33.7v18.05h10.3V26h-6.22V12.14z'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgArchitectural06
